
/*
HELL: #A9F0FF rgba(169, 241, 255, 0.6);
DUNKEL: #26435F
*/

@font-face {
  font-family: 'Major Mono Display';
  src: url(/static/media/MajorMonoDisplay-Regular.c948b92f.woff2) format('woff2'),
      url(/static/media/MajorMonoDisplay-Regular.5d769faa.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}



@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(169, 241, 255, 0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(169, 241, 255, 0.2);
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(169, 241, 255, 0.6);
		transform: translatey(0px);
	}
}

@keyframes down {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(2px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes left {
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(-5px);
	}
	100% {
		transform: translatex(0px);
	}
}

@keyframes right {
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(5px);
	}
	100% {
		transform: translatex(0px);
	}
}

body {
  margin: 0;
  padding: 10px;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  font-family: 'Major Mono Display';
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rootContainer {
  /*padding: 20px;*/
  /*background-color: transparent;*/
  background-color: rgba(38, 68, 95, 0.2);
  /*border: 1px solid rgb(250, 216, 171);*/
  /*overflow: hidden;*/
  box-shadow: 0px 0px 64px -14px rgba(169, 241, 255, 0.6);
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  border: 10px solid rgba(169, 241, 255, 0.6);
}

.mainContainer {
  
}

.gridContainer {
  overflow: hidden;
}

.cellContainer {
  float: left;
  /*border: 1px solid rgb(250, 216, 171);*/
  box-shadow: inset 0px 0px 2px 0px rgba(169, 241, 255, 0.6);
  /*background-color: rgba(38, 68, 95, 0.6);*/
  
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

@media (max-width: 767px) {
  /*
  .fullscreen-bg {
    background: url('assets/bg.jpg') center center / cover no-repeat;
  }

  .fullscreen-bg__video {
    display: none;
  }
  */
}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

